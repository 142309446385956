function compactList(lista) {
  let items = [];
  let found;

  // console.log("compactList(lista) lista: ", lista);

  lista.map((item, indx) => {
    item.index = indx;
    // console.log("indx item: ", indx, item.name);
    found = false;
    for (let idx = 0; idx < items.length; idx++) {
      // items.maps((theItem, idx) => {
      let theItem = items[idx];
      // console.log("indx, idx, theItem: ", indx, idx, theItem.name);
      // if (item.id === theItem.id) {
      if (item.name === theItem.name) {
        console.log("indx, idx, theItem, item: ", indx, idx, theItem, item);
        console.log(
          "theItem.name, theItem.quantity, item.quantity: ",
          theItem.name,
          theItem.quantity,
          item.quantity,
          theItem.qtyUnit,
          item.qtyUnit,
          theItem.number,
          item.number
        );
        if (item.qtyUnit === theItem.qtyUnit) {
          // todo:
          // check units and if necessary convert units
          if (items[idx].quantity === Number(item.quantity)) {
            items[idx].number += Number(item.number);
          }
          found = true;
          break;
        } else if (item.qtyUnit === "kg" && theItem.qtyUnit === "g") {
          items[idx].quantity += Number(
            1000 * Number.parseFloat(item.quantity)
          );
          found = true;
          break;
        } else if (item.qtyUnit === "g" && theItem.qtyUnit === "kg") {
          items[idx].quantity += Number(
            Number.parseFloat(item.quantity) / 1000
          );
          found = true;
          break;
        }
      }
      // });
    }
    if (!found) {
      // console.log("indx item: ", indx, item);
      items.push(item);
    }
    // console.log("items: ", items);
  });
  // console.log("compactList(lista) items: ", items);
  return items;
}

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export { truncate, compactList };
