import ShoppingListService from "../../services/shoppingList.service";
import InventoryService from "../../services/inventory.service";
import { saveShoppingList } from "../ShoppingList/ShoppingList.Aux";
import { compactList as compactShoppingList } from "../../helpers/helper";

let currentUser;

let items = [];
let inventoryItems = [];
let ingredientsToAddToShoppingList = [];

// function setItems(its) {
//   items = its;
// }
// function setInventoryItems(its) {
//   inventoryItems = its;
// }

/**
 * database functions
 */
async function getAndUpdateShoppingList() {
  // const errorElement = document.getElementById("error");
  // return
  ShoppingListService.getShoppingList(currentUser)
    .then(
      (response) => {
        // console.log("response.data.totalItems ", response.data.totalItems);
        // console.log(
        //   "getAndUpdateShoppingList() response.data.items ",
        //   response.data.items
        // );
        // console.log(
        //   "response.data.items[0].index ",
        //   // response.data.items[0].items[0].index
        //   response.data.items[0].index
        // );
        // console.log(response.status);
        // console.log(response.statusText);
        // console.log(response.headers);
        // console.log(response.config);
        // console.log(prevValue);
        // console.log(response.data);
        // return [...prevValue, ...response.data];
        // return [...response.data.items[0].items];
        // setItems([...response.data.items]);
        items = response.data.items;

        // console.log("got ShoppingList");
        // console.log("items ", items);
        // console.log(
        //   "ingredientsToAddToShoppingList ",
        //   ingredientsToAddToShoppingList
        // );
        // console.log("response.data.items ", response.data.items);
        // console.log("get the ingredients from this recipe");
        // ingredientsToAddToShoppingList.map((ingredient, indx) => {
        //   // console.log("ingredient: ", indx, ingredient);
        // });

        // console.log("add the ingredients");
        const theItems = combineRecipesIngredientsAndShoppingList(
          items,
          ingredientsToAddToShoppingList
        );
        // console.log("items ", items);
        // console.log("theItems ", theItems);
        // const errorElement = myRef.current;
        // console.log("errorElement: ", errorElement);
        // if (errorElement) {
        //   errorElement.hidden = true;
        // }
        // setLoading(false);
        // setMessage("OK");
        return theItems;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // const errorElement = document.getElementById("error");
        // const errorElement = myRef.current;
        // console.log("errorElement: ", errorElement);
        // if (errorElement) {
        //   errorElement.hidden = false;
        //   errorElement.innerText = "No items found. Error: " + _content;
        // }
        // setMessage(_content);
        // Set it to an empty array, since the user exists,
        // but it has NO items
        items = [];
        // setLoading(false);
      }
      // ).catch((err) => (console.log(err)));
    )
    .then(async (response) => {
      // console.log("then: response ", response);
      // console.log("compact the shoppingList");
      const compactedShoppingList = compactShoppingList(response);
      // console.log("compactedShoppingList ", compactedShoppingList);
      // console.log("save the shoppingList");
      // const errorElement = myRef.current;
      // const errorElement = document.getElementById("error");
      // saveShoppingList(errorElement, currentUser, compactedShoppingList);
      const result = await saveShoppingList(currentUser, compactedShoppingList);
      console.log("getAndUpdateShoppingList() result ", result);
      return result;
    })
    .catch((error) => {
      // setLoading(false);
      console.log("getAndUpdateShoppingList() error: ", error);
    });
}

async function getInventory(ingredients, servings, serves, ignoreInventory) {
  // return
  InventoryService.getInventory(currentUser)
    .then(
      async (response) => {
        // console.log("response.data.totalItems ", response.data.totalItems);
        // console.log("response.data.items ", response.data.items);
        // console.log(
        //   "response.data.items[0].index ",
        //   // response.data.items[0].items[0].index
        //   response.data.items[0].index
        // );
        // console.log(response.status);
        // console.log(response.data);
        // console.log(response.status);
        // console.log(response.statusText);
        // console.log(response.headers);
        // console.log(response.config);
        // const errorElement = document.getElementById("error");
        // console.log("errorElement: ", errorElement ? errorElement : "no ...");
        // if (errorElement) {
        // errorElement.hidden = true;
        // errorElement.style.display = "none"; //"block";

        // errorElement.hidden = true;
        // errorElement.innerText = response.statusText;
        // errorElement.innerText = "";
        // errorElement.style.display = "block";
        // errorElement.style.display = "none";
        // }

        if (response.status === 200) {
          if (response.data.items.length === 0) {
            // setMessage(
            //   "You have no items in your inventory. Please go shopping and update your inventory."
            // );
          } else {
            // setMessage("");
          }
        } else {
          // setMessage(response.statusText);
        }
        // console.log("errorElement: ", errorElement ? errorElement : "no ...");
        // setIsInError(false);
        // setLoading(false);

        inventoryItems = response.data.items.map((v, i) => {
          console.log(v.quantity, v.name);
          console.log(v.number);
          v.index = i;
          v.availability = true;
          return v;
        });
        // console.log("inventoryItems ", inventoryItems);

        // setInventoryItems((prevValue) => {
        //   // console.log(prevValue);
        //   // console.log(response.data);
        //   // return [...prevValue, ...response.data];
        //   // return [...response.data.items[0].items];
        //   // return [...response.data.items];
        //   return inventoryItems;
        // });

        const result = await checkInventory(
          ingredients,
          servings,
          serves,
          ignoreInventory
        );
        console.log("result ", result);
        return result;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // const errorElement = document.getElementById("error");
        // console.log("errorElement: ", errorElement ? errorElement : "no ...")
        // const errorElement = myRef.current;
        // console.log("errorElement: ", errorElement ? errorElement : "no ...");
        // console.log("error: ", error ? error : "no error");
        console.log("_content: ", _content ? _content : "no _content");

        // if (errorElement) {
        //   errorElement.hidden = false;
        //   errorElement.innerText = "No items found. Error: " + _content;
        //   errorElement.style.display = "block";
        // }
        // console.log("errorElement: ", errorElement ? errorElement : "no ...");
        // setIsInError(true);
        // setMessage(_content);

        // Set it to an empty array, since the user exists,
        // but it has NO items
        // setInventoryItems([]);
        inventoryItems = [];
        // setLoading(false);
      }
      // ).catch((err) => (console.log(err)));
    )
    .catch((e) => {
      console.log("getInventory() error: ", e);
      // setLoading(false);
    });
}

async function checkInventory(ingredients, servings, serves, ignoreInventory) {
  // console.log("servings: ", servings);
  // console.log("serves: ", serves);
  // console.log("checkInventory() ingredients: ", ingredients);
  // console.log("checkInventory() inventoryItems: ", inventoryItems);
  ingredientsToAddToShoppingList = [];
  ingredients.map((object, indx) => {
    // console.log("ingredient id: ", indx, object.ingredient.name);
    // ingredientsToAddToShoppingList[indx] = object.ingredient.id;
    const ingredient = object.ingredient;
    // the object is an ingredient object,
    // stripped already off all the object features like _id, etc.
    // ingredient.id = object.id;
    // console.log("object: ", indx, object);
    // console.log("ingredient: ", indx, ingredient);
    // here we add only the quantity needed by the recipe
    // console.log("servings: ", indx, servings[indx]);
    // console.log("serves: ", indx, serves[indx]);
    // console.log("servings: ", indx, servings);
    // console.log("serves: ", indx, serves);

    // console.log(
    //   "before :: indx, ingredient.quantity, ingredient.availibility: ",
    //   indx,
    //   ingredient.quantity,
    //   ingredient.availibility
    // );
    const areIngredientsForRecipe = Array.isArray(serves);
    // console.log("areIngredientsForRecipe: ", areIngredientsForRecipe);
    // console.log("ignoreInventory: ", ignoreInventory);
    if (ignoreInventory === undefined) ignoreInventory = true;
    // if (serves) {
    if (areIngredientsForRecipe) {
      ingredient.quantity =
        (ingredient.quantity * serves[indx]) / servings[indx];
    } else {
      if (serves !== servings) {
        console.log("serves !== servings: ", serves !== servings);
        ingredient.quantity = (ingredient.quantity * serves) / servings;
      }
    }
    // }
    // console.log("ingredient.quantity: ", indx, ingredient.quantity);
    // console.log("after :: ingredient.quantity: ", ingredient.quantity);

    // if the quantity needed is on stock, then do NOT add it to the list
    // find the ingredient in the inventory
    let ingredientInStock = false;
    let item;
    for (let i = 0; i < inventoryItems.length; i++) {
      item = inventoryItems[i];
      // console.log(
      //   "i inventoryItems.length, inventoryItems[i], inventoryItems ",
      //   i,
      //   inventoryItems.length,
      //   inventoryItems[i],
      //   inventoryItems
      // );
      if (item.id === ingredient.id) {
        // console.log(
        //   "item in inventory: item, ingredient ",
        //   item.name,
        //   ingredient.name
        // );
        // console.log("item, ingredient ", item.id, ingredient.id);
        if (areIngredientsForRecipe || !ignoreInventory) {
          ingredientInStock = true;
          ingredient.availability = true;
        } else {
          ingredient.availability = false;
        }
        break;
      }
    }
    // console.log("ingredientInStock: ", ingredientInStock);
    // console.log(
    //   "indx ingredient.availability: ",
    //   indx,
    //   ingredient.availability
    // );
    // console.log(
    //   "ignoreInventory || !ingredientInStock || !ingredient.availability: ",
    //   ignoreInventory || !ingredientInStock || !ingredient.availability
    // );
    if (ignoreInventory || !ingredientInStock || !ingredient.availability) {
      // console.log("ingredient to add to ShoppingList: ", ingredient.name);
      ingredientsToAddToShoppingList.push(ingredient);
    } else {
      const quantityNeeded = ingredient.quantity;
      const quantityAvailable = item.quantity;
      // console.log(
      //   "ingredient.name quantityNeeded, quantityAvailable ",
      //   ingredient.name,
      //   quantityNeeded,
      //   quantityAvailable
      // );
      if (quantityAvailable < quantityNeeded) {
        console.log(
          "ingredient.name quantityNeeded - quantityAvailable ",
          ingredient.name,
          quantityNeeded - quantityAvailable
        );
        ingredient.quantity = quantityNeeded - quantityAvailable;
        ingredientsToAddToShoppingList.push(ingredient);
      } else {
        // console.log(ingredient.name, " not need to buy ");
      }
    }
  });
  // console.log("addIngredientsToShoppingList(): currentUser ", currentUser);
  // console.log("addIngredientsToShoppingList(): ingredients ", ingredients);
  console.log(
    "addIngredientsToShoppingList(): ingredientsToAddToShoppingList ",
    ingredientsToAddToShoppingList
  );
  console.log("get the shoppingList");
  const result = await getShoppingList();
  return result;
}

function combineRecipesIngredientsAndShoppingList(
  items,
  ingredientsToAddToShoppingList
) {
  const theItems = [...items, ...ingredientsToAddToShoppingList];
  items = theItems;
  return theItems;
}

async function getInventoryList(
  ingredients,
  servings,
  serves,
  ignoreInventory
) {
  // handleSave(items);
  // setLoading(true);
  async function fetchData() {
    // You can await here
    const response = await getInventory(
      ingredients,
      servings,
      serves,
      ignoreInventory
    );
    // ...
    console.log("response ", response);
    return response;
  }
  const response = await fetchData();
  // console.log("updating");
  console.log("response ", response);
  return response;
}

async function getShoppingList() {
  // handleSave(items);
  // setLoading(true);
  async function fetchData() {
    // You can await here
    const response = await getAndUpdateShoppingList();
    // ...
    console.log("response ", response);
    return response;
  }
  const response = await fetchData();
  // console.log("updating");
  console.log("getShoppingList() response ", response);
  return response;
}

const addIngredientsToShoppingList = async (
  theUser,
  ingredients,
  servings,
  serves,
  ignoreInventory
) => {
  currentUser = theUser;
  console.log(
    // " theUser, ingredients, servings, serves: ",
    " ingredients, servings, serves: ",
    // theUser,
    ingredients,
    servings,
    serves
  );
  console.log("ignoreInventory", ignoreInventory);

  // the ingredients come in in "tramos" (stretches)
  // const div1 = Array.from({ length: serves[2] }, (value, indx) => serves[0]);
  // const div2 = Array.from({ length: serves[3] }, (value, indx) => serves[1]);
  // const div = [...div1, ...div2];
  // console.log("div1 ", div1);
  // console.log("div2 ", div2);
  // console.log("div ", div);
  return await getInventoryList(ingredients, servings, serves, ignoreInventory);
};

export default addIngredientsToShoppingList;
